import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthFlow from './components/AuthFlow/AuthFlow';
import { UserProvider } from './contexts/UserContext';
import './i18n';

function App(): JSX.Element {
    console.log(`Commit: ${process.env.REACT_APP_COMMIT_SHA}`);

    return (
        <>
            <UserProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Navigate to="/verify-email" />} />
                        {/* Catch-all route for undefined paths and direct to AuthFlow */}
                        <Route path="*" element={<AuthFlow />} />
                        {/* Redirect from index.html to root */}
                        <Route path="/index.html" element={<Navigate to="/" replace />} />
                        {/* Catch-all route for undefined paths */}
                        <Route path="*" element={<Navigate to="/verify-email" replace />} />
                    </Routes>
                </Router>
            </UserProvider>
        </>
    );
}

export default App;
