import { createContext, useState, useContext, ReactNode } from 'react';

type UserContextType = {
    email: string;
    setEmailValue: (newEmail: string) => void;
}

const UserContext = createContext<UserContextType>({
    email: '',
    setEmailValue: () => {
        throw new Error('setEmailValue was called outside of a UserProvider');
    },
});

export const useUser = (): UserContextType => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }: {children: ReactNode}) => {
    const [email, setEmail] = useState<string>('');

    const setEmailValue = (newEmail: string): void => {
        setEmail(newEmail);
    };

    const contextValue: UserContextType = { email, setEmailValue };

    return (
        <UserContext.Provider value={ contextValue }>
            { children }
        </UserContext.Provider>
    );
};

export default UserContext;
