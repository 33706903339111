import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import type for resources
import { Resource } from 'i18next';

// Import JSON resources
import en from './locales/en.json';
import de from './locales/de.json';

// Define the resource type
const resources: Resource = {
    en: { translation: en },
    de: { translation: de },
    // Add more languages here
};

// Initialize i18n
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default i18n;
