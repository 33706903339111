import {ComponentType} from 'react';
import EmailPopUp from '../components/EmailPopUp/EmailPopUp';
import CodeConfirmPopUp from '../components/CodeConfirmPopUp/CodeConfirmPopUp';
import ConsentPopUp from '../components/ConsentPopUp/ConsentPopUp';

const contentMap: Record<string, ComponentType> = {
    '/verify-email': EmailPopUp,
    '/verify-code': CodeConfirmPopUp,
    '/consent': ConsentPopUp,
};

export const getPopupComponent = (pathname: string): ComponentType => {
    return contentMap[pathname] || EmailPopUp;
};
